<template>
   <v-layout pa-4 align-center justify-center class="backgroud">
      <h1>Aqui será nossa futura <strong>Home</strong> :)</h1>
   </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
   computed: {
      ...mapGetters('rasaServices', [
         'services',
      ]),
   },

   created() {
      this.goToService(this.services)
   },

   methods: {
      goToService(services) {
         if (services.length == 0) {
            return
         }

         let service = services[0]

         console.log('Redirecionando para ', service.type)

         this.$router.push({
            name: 'cs2_loadingPage',
            params: {
               service_type: service.type,
            },
         })
      },
   },

   watch: {
      services: 'goToService',
   },
};
</script>

<style>
   /* .backgroud {
        background: #f1f1f1;
        color: #08977E;
        height: 100vh;
    } */
</style>
